import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AgoraNurseCall } from "./AgoraNurseCall";
import { AgoraDoctorCall } from "./AgoraDocCall";
import CallActions from "./CallActions";
import VideoPlayer from "./VideoPlayer";
import { AgoraDevice } from "./AgoraDevice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import WebSocketService from "../../services/Notification/socketService";
import { useAvailableCameras } from "../../hooks/useAvailableCameras";
import APP_CONSTANTS, { TABLE_HEAD, videoCallDevices } from "../../constants";
import { io } from "socket.io-client";
import {
  useGetTeleconsultationQuery,
  useLazyGetTeleconsultationQuery,
} from "../../features/teleconsultation/teleconsultationApi";
import { CircularProgressbar } from "react-circular-progressbar";
import { AgoraScreenShare } from "./AgoraScreenShare";
import { ExeName } from "../../enums/vitalSigns";
import Drawer from "../Drawer";
import { Transition } from "@headlessui/react";
import { updateAgentStatus } from "../../features/indicators/systemIndicatorSlice";
import { AgentStatus } from "../../enums/agent";
import {
  selectShowOthers,
  setMute,
} from "../../features/teleconsultation/CallActionsSlice";
import CallTimer from "./callTimer";
import { stopShareScreen } from "../../helper/mediaDeviceHelper";
import {
  GlobalStyle,
  Wrapper,
  CallDivParent,
  CallDiv,
  MyCameraWrapper,
  DrawerContainer,
  BottonsWrrapper,
  CallTools,
} from "../../StyledComponents";
import {
  Card,
  Dialog,
  DialogBody,
  DialogHeader,
  Spinner,
  Typography,
} from "@material-tailwind/react";
import { role, userStatus } from "../../enums/app";
import { useGetCallQuery } from "../../features/call/callApi";
import userInCall from '../../assets/userInCall.svg'
import moment from "moment";
// import { AgoraNurseCall } from "./AgoraNurseCall";;

import voiceIcon from "../../assets/voiceIcon.svg";
import CameraSvg from "../../assets/cameraSvg.svg";
import { setMainCameraDeviceId } from "../../features/teleconsultation/mainCameraIdSlice";
const Call = () => {

  // hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { availableVideaoDevices } = useAvailableCameras();
  const [getTeleconsultation] = useLazyGetTeleconsultationQuery({});
  // const { oxymeterCallBackStatus, nibpCallBackStatus } = useAgentSocket();

  // selectors
  const storedUser = useSelector((state: RootState) => state.auth.user);
  const [isSpecialist, setIsSpecialist] = useState<boolean>(
    storedUser?.roles[0].name === role.SPECIALIST
  );

  // roomId, the unique identifier of the call
  const { id: roomId } = useParams();

  const { data, isLoading, error } = useGetCallQuery(roomId);

  const [interlocutor, setInterlocutor] = useState<{ fullname: string, speciality?: string }>()


  const [isShareScreen, setIsShareScreen] = useState(false);

  const [ischat, setIschat] = useState(false);

  const [streamDeviceId, setStreamDeviceId] = useState<string | null>(null);

  const [checkedDevice, setCheckedDevice] = useState(-1);

  const [clicked, setClicked] = useState(false);

  const location = useLocation();

  const [users, setUsers] = useState<any>([]);

  const [toolName, setToolName] = useState<string>("");

  const [availableSensors, setAvailableSensors] = useState<any>([]);

  const [showDevice, setShowDevice] = useState(false);

  const [currentUser, setCurrentUser] = useState<any>(null);

  const [currentDeviceStream, setCurrentDeviceStream] = useState<any>(null);

  const [currentUserStream, setCurrentUserStream] = useState<any>(null);

  const [currentShareScreen, setCurrentShareScreen] = useState<any>(null);

  const [isAllowedAccessCall, setIsAllowedAccessCall] = useState<boolean>(true);
  const [openChooseCamera, setOpenChooseCamera] = useState(false);
  const [showNurse, setShowNurse] = useState(false);

  // the array containning the user ids of the particiants
  const [paticipantsUserIds, setParticipantsUserIds] = useState<Array<number>>([]);

  const [isVideoStream, setIsVideoStream] = useState(false);

  const [callToken, setCallToken] = useState<string | null>(null);

  const [mediotAgent, setMediotAgent] = useState<any>(null);

  const mediaDevices = useSelector((state: RootState) => state.mediaDevices);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const [currentTime, setCurrentTime] = useState(0);

  const hours = String(Math.floor(currentTime / 3600)).padStart(2, "0");

  const minutes = String(Math.floor((currentTime % 3600) / 60)).padStart(
    2,
    "0"
  );

  const [callCurrentDuration, setCallCurrentDuration] = useState<number | null>(null);

  const seconds = String(currentTime % 60).padStart(2, "0");

  const timeString = `${hours}:${minutes}:${seconds}`;
  // watchers
  const availableMainCameras = useSelector(
    (state: RootState) => state.defaultMainCameras
  );
  const showOthersState = useSelector(selectShowOthers);

  // check if user can join the call
  useEffect(() => {
    if (!isLoading) {
      // Assuming callStartedAt is a JavaScript datetime
      const callStartedAt = data?.createdAt; // Replace this with your datetime value

      // Convert callStartedAt to a Unix timestamp in milliseconds
      const callStartedTimestamp = moment(callStartedAt).valueOf();

      // Get the current time in milliseconds
      const currentTime = Date.now();

      // Calculate the difference in seconds
      const differenceInSeconds = Math.floor((currentTime - callStartedTimestamp) / 1000);

      // Update the call duration state or variable
      setCallCurrentDuration(differenceInSeconds);

      console.log("Duration in seconds:", differenceInSeconds);

      if (isSpecialist) {
        setInterlocutor({ fullname: data?.station.center.name })
      } else {
        setInterlocutor({ fullname: `${data?.user.firstName} ${data?.user.lastName}`, speciality: data?.user?.doctor?.speciality?.name })
      }


      // if (error && error.status == 403) setIsAllowedAccessCall(false);
      // else setIsAllowedAccessCall(true);

      /* store current call */
      localStorage.setItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}currentCall`, data?.id)
    } else {
      setIsAllowedAccessCall(true);
      setParticipantsUserIds([]);
    }
  }, [isLoading]);


  /**
   * on unmount component, update 
   */
  useEffect(() => {
    return () => {
      WebSocketService.updateDoctorStatus(userStatus.ONLINE, storedUser?.userId);

    };
  }, []);



  useEffect(() => {
    /** get token from service */
    if (roomId) {
      WebSocketService.updateDoctorStatus(userStatus.IN_CALL, storedUser?.userId);

      WebSocketService.joinRoom(roomId, storedUser?.userId!, (data) => {
        getTeleconsultation({
          param1: roomId,
          param2: "0",
        })
          .unwrap()
          .then((res) => {
            console.log("getTeleconsultation", res);

            setCallToken(res.token);
          })
          .catch((err) => {
            console.log("getTeleconsultation", err);
            // setCallToken(err.data);
          });
      });
    } else {
      navigate("/notAllowed");
    }


    if (!isSpecialist) connectMediot();

    // Function to update the time every second
    const updateCurrentTime = () => {
      setCurrentTime((prevTime) => prevTime + 1);
    };

    // Start the timer immediately
    const timer = setInterval(updateCurrentTime, 1000);



    // Clear the interval on component unmount
    return () => {
      if (!isSpecialist) mediotAgent?.close();
      clearInterval(timer);
    };
  }, []);




  /**
   * Effect of user clicks
   */
  useEffect(() => {
    if (
      (!clicked && users.length > 2) ||
      (!clicked && currentDeviceStream?.audioTrack)
    ) {
      stopStream(currentDeviceStream, (data: any) => {
        if (data?.status === "success") setShowDevice(false);
      });
    }

    if (isShareScreen) {
      stopShareScreen(currentShareScreen, (data: any) => {
        if (data?.status === "success") { console.log("[STOP SHARE] share screen success"); }
        else {
          console.error("[STOP SHARE] share screen error, stream >", currentShareScreen)
          //TODO: dispatch message for share screen
        }
        setIsShareScreen(false);
      }
      );
      return;
    }
    // if (deviceRef.current?.srcObject && !deviceIsClicked) {
    //   console.log("remove stream");
    //   stopVideoTrack();
    //   setStreamDeviceId(null);
    //   setMultimediaStream(null);
    // }
    // }

    switch (checkedDevice) {
      case 0:
        // if (deviceIsClicked) {
        //   console.log("ecg_report_cmd", agentSocket);
        //   agentSocket?.emit("ecg_report_cmd");
        //   agentSocket?.on("ecg_report", (data: any) => {
        //     const { link, pdfBase64, absolute } = JSON.parse(data);
        //     console.log(" res ecg_report_cmd", pdfBase64);

        //     //TODO::send data to backend as a file
        //     handleDecodeAndCheck(pdfBase64, link, absolute);
        //     // navigat(link);
        //   });
        // }
        break;

      case 1:
        if (mediaDevices.dermatoscope && clicked) {
          // console.log(mediaDevices.dermatoscope, deviceIsClicked);
          setShowDevice(true);
          setIsVideoStream(true);
          setStreamDeviceId(mediaDevices.dermatoscope);
        }

        break;
      case 2:
        if (mediaDevices.otoScope && clicked) {
          setShowDevice(true);
          setIsVideoStream(true);
          setStreamDeviceId(mediaDevices.otoScope);
        }
        break;
      case 5:
        if (callToken && clicked) {
          // if (availableServices.includes(ExeName.ECHO) && deviceIsisClicked) {
          //   WebSocketService.showScreen(ExeName.ECHO);
          //   setDeviceIsisClicked(false);
          setIsShareScreen(true);
        }
        break;
      case 3:
        if (mediaDevices.iriscope && clicked) {
          setShowDevice(true);
          setStreamDeviceId(mediaDevices.iriscope);
          setIsVideoStream(true);
        }
        break;
      case 4:
        if (mediaDevices.cameraMobile && clicked) {
          setShowDevice(true);
          setStreamDeviceId(mediaDevices.cameraMobile);
          setIsVideoStream(true);
        }
        break;

      case 6:
        //TODO: doing so for base params
        // setInterval(() => {
        //   WebSocketService.sendBaseParamsCallData(
        //     JSON.stringify({ roomId:roomId, message: "hello" })
        //   );
        // }, 1000);
        break;

      case 10:
        if (mediaDevices.Stetoscope && clicked) {
          setShowDevice(true);
          setStreamDeviceId(mediaDevices.Stetoscope);
          setIsVideoStream(false);
        }
        break;

      default:
        break;
    }

    // to remove the consumption of echographie pdf
    // if (!deviceIsClicked && toolsIndex === 5) {
    //   toast.success("ECG est bien arrêté");
    //   agentSocket?.off("ecg_report_cmd");
    //   // exitApp();
    // }
  }, [clicked, checkedDevice]);



  useEffect(() => {
    WebSocketService.RecvBaseParamsCallData((data: any) => {
      console.log("RecvBaseParamsCallData", data);
    });

  }, []);




  useEffect(() => {
    console.log("currentUser", currentDeviceStream);
  }, [currentDeviceStream]);

  useEffect(() => {
    console.log("availableMainCameras", availableMainCameras);
    if (showOthersState) setOpenChooseCamera(true);
  }, [showOthersState]);

  /** mediot Agent connection*/
  const connectMediot = () => {
    console.log("connection to agent...");
    if (!mediotAgent) {
      const agent = io(`${process.env.REACT_APP_MEDIOT_AGENT_URL}`, {
        reconnection: true,
        reconnectionDelay: 5000,
        reconnectionAttempts: 10,
      });

      setMediotAgent(agent);

      agent.on("connect_error", (reason: any) => {
        dispatch(updateAgentStatus(AgentStatus.Error));
        console.warn("[connection d'agent]", reason);
        // toast.error(
        //   "Veuillez essayer d'exécuter l'Agent sur votre VisioStation."
        // );
      });

      agent.on("connect", () => {
        //TODO: set the availability of the sensors based on the data received
        agent.on("available_sensors", (data: any) => {
          const values = JSON.parse(data);

          // get the name of the only true values
          const sensors = Object.keys(values).filter((key) => values[key]);
          console.log("sensor", sensors);
          setAvailableSensors(sensors);
        });
      });
    }
  };



  // hanlers
  const handelDeviceClick = (deviceIndex: number) => {
    setCheckedDevice(deviceIndex);
    setClicked(!clicked);
  };

  const stopStream = (
    currentStream: any,
    callback: (data: any) => void
  ) => {
    try {
      console.log("stopStream **");
      if (currentStream.videoTrack) {
        currentStream.videoTrack.stop();
        currentStream.videoTrack.close();
        currentStream.client.unpublish();
      }
      if (currentStream.audioTrack) {
        currentStream.audioTrack.stop();
        currentStream.audioTrack.close();
        dispatch(setMute());
        setTimeout(() => {
          dispatch(setMute());
        }, 1000);
      }
      currentStream.client.remoteUsers.forEach((user: any) => {
        if (user.hasVideo) {
          //   removeVideoContainer(user.uid)
        }
        currentStream.client.unsubscribe(user);
      });
      currentStream.client.removeAllListeners();
      console.log("client", currentStream.client, "currentStream", currentStream);
      callback({ status: "success" });
    } catch (error) {
      console.log("stop stram error", error);
      callback({ status: "error" });
    }
  };

  useEffect(() => {
    console.log("mediaDevices", mediaDevices);
    if(!mediaDevices) return ;
    if (
      Object.keys(mediaDevices).length == 0
    ) {
      console.log("show Device");
      setShowNurse(true);
    }
  }, [mediaDevices]);

  useEffect(() => {
    WebSocketService.RecvBaseParamsCallData((data: any) => {
      console.log("RecvBaseParamsCallData", data);
    });
  }, []);

  useEffect(() => {
    console.log("users", users);
  }, [users]);

  const handleOpen = (index: number) => {
    setOpenChooseCamera(!openChooseCamera);
  };

  if (isAllowedAccessCall) return (
    <>
      <GlobalStyle>
        <div className="relative w-full ">
          <div className="flex">
            <Wrapper>
              <CallDivParent>
                {users.length <= 1 ?
                  <div className='flex flex-col gap-3 text-white items-center'>
                    <img src={userInCall} />
                    <p className='text-2xl font-semibold'>{interlocutor?.fullname}</p>
                    {!isSpecialist && <p className='text-sm font-thin'>{interlocutor?.speciality}</p>}                  </div>
                  :
                  users.map((user: any, index: any) => {
                    // return index !== 0 && user.uid !== deviceUid ? (
                    return (
                      user.uid !== currentUser && (
                        <CallDiv>
                          {user?.kind ? <div className="pt-3 pb-0 text-white font-bold">
                            {/* {user.uid} */}
                            {user.kind}
                          </div> : null
                          }
                          <VideoPlayer
                            key={user.uid}
                            user={user}
                            size={"w-[100%] h-[100%]"}
                          />
                        </CallDiv>
                      )
                    );
                  })}
                {/* {
                  Object.keys(mediaDevices).length === 0 && (
                    <InteractionMessages />
                  )
                } */}
                {/* <CallDivModal>
              <BaseParamsPanel isOpen={isModalOpen}>
                <ProgressCircle progress={progress}>
                  {progress === 100 && <div>Result</div>}
                </ProgressCircle>
              </BaseParamsPanel>
            </CallDivModal> */}
                {/* <BaseParamsPanel /> */}
                {callCurrentDuration && <CallTimer
                  startTime={callCurrentDuration}

                />}

                <MyCameraWrapper className={!isSpecialist ? "bottom-[10rem]" : "bottom-2"}>
                {isSpecialist ? (
                    <AgoraDoctorCall
                      token={callToken}
                      channel={roomId}
                      users={users}
                      setUsers={setUsers}
                      setCurrentUser={setCurrentUser}
                      currentUser={currentUser}
                    />
                  ) : !callToken ? <Spinner /> : null}

                    {/* {!isSpecialist && Object.keys(mediaDevices).length > 0 && ( */}
                    {!isSpecialist ? (
                      showNurse &&
                      callToken && (
                        <AgoraNurseCall
                          token={callToken}
                          channel={roomId}
                          users={users}
                          setUsers={setUsers}
                          currentUser={currentUser}
                          setCurrentUser={setCurrentUser}
                          mediaDevices={mediaDevices}
                        />
                      )
                    ) : !isSpecialist ? (
                      <>
                      <img src={voiceIcon} className="self-end mr-2" />
                      <img src={userInCall} className="w-20 h-20" />
                      <p className="self-start m-2"></p>
                    </>
                    ) : null}
                  </MyCameraWrapper>

                {showDevice && (
                  <>
                    <AgoraDevice
                      channel={roomId}
                      token={callToken}
                      deviceId={streamDeviceId}
                      deviceUid
                      setCurrentDeviceStream={setCurrentDeviceStream}
                      users={users}
                      setUsers={setUsers}
                      isVideoStream={isVideoStream}
                    />
                  </>
                )}

                  {isShareScreen && (
                    <>
                      <AgoraScreenShare
                        token={callToken}
                        channel={roomId}
                        deviceId={streamDeviceId}
                        isShareScreen={isShareScreen}
                        deviceUid
                        setCurrentShareScreen={setCurrentShareScreen}
                        users={users}
                        setUsers={setUsers}
                      />
                    </>
                  )}
                </CallDivParent>
              </Wrapper>
              <DrawerContainer>
                <Transition
                  className={"mb-4 w-full"}
                  enter="transition-opacity ease-linear duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity ease-linear duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  show={drawerOpen}
                >
                  <Drawer setDrawerOpen={setDrawerOpen} open={drawerOpen} />
                </Transition>
              </DrawerContainer>
            </div>
            <BottonsWrrapper isSpecialist={isSpecialist}>
              {!isSpecialist && (
                <CallTools>
                  {videoCallDevices.map((tool, index) => {
                    return (
                      <>
                        <img
                          src={tool.icon}
                          onClick={() => {
                            handelDeviceClick(index);
                            setToolName(tool.name);
                          }}
                          className={`baseParamsIcon ${
                            (availableSensors &&
                              availableSensors.includes(tool.name)) ||
                            (mediaDevices && mediaDevices[tool.name]) ||
                            tool.name === ExeName.ECHO ||
                            tool.name === "styto"
                              ? /*availableSensors[tool.name] &&*/
                                //|| tool.name === "ecg" ||
                                //availableServices.includes(tool.name)
                                ""
                              : "filter grayscale hover:pointer-events-none"
                          }
                  ${
                    clicked &&
                    checkedDevice === index &&
                    "scale-150 brightness-150 animate-pulse"
                  }
                  `}
                          alt=""
                          style={{ cursor: "pointer", minWidth: "30px" }}
                        />
                      </>
                    );
                  }, [])}
                </CallTools>
              )}
               {(data && data?.consultationId) && <CallActions
              callInformations={data}
              consultationId={data?.consultationId}
              roomId={roomId!}
              isShareScreen={isShareScreen}
              setIsShareScreen={setIsShareScreen}
              isSpecialist={isSpecialist}
              drawerOpen={drawerOpen}
              setDrawerOpen={setDrawerOpen}
            />}
            </BottonsWrrapper>
          </div>
          {
            <Dialog open={openChooseCamera} handler={handleOpen}>
              <DialogBody>
                <DialogHeader className="flex items-center">
                  <img src={CameraSvg} className="w-[30px] h-[30px] mr-4"></img>
                  {"Merci de sélectionner source d'entrée"}
                </DialogHeader>

                <Card className="h-full w-full overflow-scroll">
                  <table className="w-full min-w-max table-auto text-left">
                    <thead>
                      <tr>
                        {TABLE_HEAD.map((head) => (
                          <th
                            key={head}
                            className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                          >
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal leading-none opacity-70"
                            >
                              {head}
                            </Typography>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {availableMainCameras.mainDevices.map(
                        (device: any, index: number) => {
                          const isLast =
                            index ===
                            availableMainCameras.mainDevices.length - 1;
                          const classes = isLast
                            ? "p-4"
                            : "p-4 border-b border-blue-gray-50";

                          return (
                            <tr key={device.category}>
                              <td className={classes}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {device.category}
                                </Typography>
                              </td>
                              <td className={classes}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {device.kind == 'audioInput' && "Source d'audio"}
                                  {device.kind == 'videoInput' && "Source de video"}
                                </Typography>
                              </td>
                              <td className={classes}>
                                <Typography
                                  as="a"
                                  variant="small"
                                  color="blue-gray"
                                  className="font-medium cursor-pointer"
                                  onClick={() => {
                                    console.log("clicked");

                                    dispatch(
                                      setMainCameraDeviceId({
                                        deviceId: device.deviceId,
                                        kind: device.kind,
                                        category: device.category,
                                      })
                                    );
                                    setOpenChooseCamera(false);
                                  }}
                                >
                                  <span className="bg-green-100 text-green-800 text-lg font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400">
                                    Choisir
                                  </span>
                                </Typography>
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </Card>
              </DialogBody>
            </Dialog>
          }
        </GlobalStyle>{" "}
      </>
    );
  else if (!isLoading && !isAllowedAccessCall)
    return <>vous n'etes pas autorisé a rejoindre l'appel</>;
  else if (isLoading) return <>Loading call data...</>;
};

export default Call;
