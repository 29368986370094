import axios from "axios";
import APP_CONSTANTS from "../constants";
import instance from "./Network";

// const instance = axios.create({
//   baseURL: process.env.REACT_APP_SERVER_URL + "/",
//   headers: {
//     "ngrok-skip-browser-warning": "any",
//   },
// });

// instance.interceptors.request.use(async (config) => {
//   const token = localStorage.getItem(
//     `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}token`
//   );
//   if (token) {
//     config.headers.Authorization = "Bearer " + token;
//   }
//   config.headers.ContentType = "multipart/form-data";
//   return config;
// });

export const postMedicalHistory = async (data: any, id: number) => {
  try {
    let res = await instance.post(`/medicalhistory/${id}`, data);
    // console.log(res);
  } catch (error) {
    // console.log(error);
  }
};
export const getMedicalHistory = async () => {
  try {
    let res = await instance.get("/medicalhistory");
    // console.log(res);
    return res.data.results;
  } catch (error) {
    // console.log(error);
  }
};

export const postConsutation = async (data: any, formData: any) => {
  try {
    let res = await instance.post(`/consultation`, data);
    // console.log(res);
    const id = res.data.id;
    const formDataArray = formData.map((form: any) => {
      const copiedForm = new FormData();
      for (const [key, value] of form.entries()) {
        copiedForm.append(key, value);
      }
      copiedForm.append("consultationId", id);
      // copiedForm.append("specialityId", "2");
      copiedForm.append("userId", "9");
      return copiedForm;
    });
    formDataArray.forEach((formData: any, index: number) => {
      // console.log(`FormData ${index}:`);
      for (const [key, value] of formData.entries()) {
        // console.log(`${key}: ${value}`);
      }
    });

    const requests = [];
    for (let i = 0; i < formDataArray.length; i++) {
      const formData = formDataArray[i];

      requests.push(instance.post(`/attachement`, formData));
    }

    await Promise.all(requests);
    // Wait for all requests to complete
  } catch (error) {
    // console.log(error);
  }
};
export const sendFileByConsultationId = async (formData: any) => {
  return await instance.post(`/attachement`, formData);
};
export const getAttachmentById = async (id: number) => {
  return await instance.get(`/attachement?where={"consultationId":${id}}`);
};

export const getConsultationById = async (id: any) => {
  return await instance.get(`/consultation/${id}`);
};

export const getConsultation = async (
  filter: string,
  patientId: number,
  ongoing?: boolean
) => {
  // inititialise conditions
  let params = {
    // take: "1000",
    where: JSON.stringify({
      patientId,
      // ...conf,
      // doctorId: doc,
    }),
    include: JSON.stringify({
      attachements: true,
      chiefComplaintConsultations: {
        include: {
          chiefComplaint: true,
        },
      },
      medicationConsultations: {
        include: {
          medication: true,
        },
      },
      imagingConsultations: {
        include: {
          imaging: true,
        },
      },
      medicalActConsultations: {
        include: {
          medicalAct: true,
        },
      },
      analyseConsultations: {
        include: {
          analyse: true,
        },
      },
      doctor: {
        include: {
          user: true,
          speciality: true,
        },
      },
      specialist: {
        include: {
          user: true,
          speciality: true,
        },
      },
    }),
    orderBy: JSON.stringify({
      createdAt: "desc",
    }),
  };

  let conf = {};

  let doc: number | null = null;

  // if (filter === "all") {
  //   // Fetch all consultations
  //   console.log("all");
  // } else if (Number.isInteger(parseInt(filter))) {
  //   // Fetch consultations by speciality ID
  //   conf = {
  //     doctor: {
  //       specialityId: filter,
  //     },
  //   };
  // } else if (filter === "myConsultations") {
  //   // Fetch consultations by doctor ID
  //   doc = 2;
  // }

  if (doc) {
    params = {
      ...params,
      where: JSON.stringify({
        ...JSON.parse(params.where),
        doctorId: doc,
      }),
    };
  }
  if (conf) {
    params = {
      ...params,
      where: JSON.stringify({
        ...JSON.parse(params.where),
        ...conf,
      }),
    };
  }

  if (ongoing) {
    params = {
      ...params,
      where: JSON.stringify({
        ...JSON.parse(params.where),
        // NOT: {
        //   endTime: null,
        // },
      }),
    };
  }

  try {
    let res = await instance.get("/consultation?", {
      params: params,
    });
    // console.log(res);
    return res.data.results;
  } catch (error) {
    // console.log(error);
  }
};
export const getMedicationConsultation = async (id: number) => {
  try {
    let res = await instance.get(`/medicationconsultation/${id}`);
    // console.log(res);
    return res.data;
  } catch (error) {
    // console.log(error);
  }
};

export const getSpeciality = async () => {
  try {
    let res = await instance.get("/speciality");
    // console.log(res);
    return res.data.results;
  } catch (error) {
    // console.log(error);
  }
};

export const getPatientMedicalHistory = async (id: number) => {
  // console.log("getting data of patient", id);
  try {
    let res = await instance.get(`/patient/${id}`, {
      params: {
        include: JSON.stringify({
          medicalHistory: true,
        }),
      },
    } as any);
    // console.log("medical history of the patient", res);
    return res.data;
  } catch (error) {
    // console.log(error);
  }
};
export const getAppointements = async () => {
  try {
    let res = await instance.get(`/appointment/`, {
      params: {
        include: JSON.stringify({
          slot: true,
        }),
      },
    } as any);
    // console.log(res);
    return res.data;
  } catch (error) {
    // console.log(error);
  }
};

export const getPatient = async (id: number) => {
  try {
    let res = await instance.get(`/patient/${id}`, {
      params: {
        include: JSON.stringify({
          user: true,
          city: {
            include: { province: true },
          },
          haveRights: {
            include: {
              user: true,
            },
          },
        }),
      },
    } as any);
    // console.log(res);
    return res.data;
  } catch (error) {
    // console.log(error);
  }
};
