import { useEffect, useState } from "react";
import { Dialog, Spinner } from "@material-tailwind/react";
import MyButton from "../common/Button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import { setRightIsOpen } from "../../features/sidebar/SidebarSlice";
import "dayjs/locale/fr";
import { cancelAppointment, changeStatusAppointment, getDoctorNamebyId, notifySpecilist } from "../../axiosAPI/Network";
import { useGetOnGoingPatientConsultationQuery } from "../../features/consultationData/consultationDataApi";
import {
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";

import { userStatus } from "../../enums/app";
import { useGetDoctorByIdQuery } from "../../features/doctor/doctorApi";
import { useGetConsultationOngoingCallQuery } from "../../features/call/callApi";
import APP_CONSTANTS from "../../constants";


/**
 * messages that will be displayed in the alert based on the specialist status
 */
enum texts {
  SPECIALIST_NOT_NOTIFIED = 'Veuillez démarrer une consultation pour le patient.',
  SPECIALIST_IN_CALL = 'Le médecin spécialiste est en appel pour le moment',
  SPECIALIST_OFFLINE = `Le médecin spécialiste n'est pas disponible pour le moment`
}



/**
 * Availability criterias are:
 * - specialist is online (socket status, and http status)
 * - specialist is not in a call (onGoing calls is empty, or if not, the call is not of the target user)
 * - already openned consultation by the generalist
 * @returns 
 */
const EventModal = () => {

  dayjs.locale("fr");

  /**
   * 
   */
  const dispatch = useDispatch();


  const nav = useNavigate();



  /* this loading state will be controlled when all conditions are available */
  const [loadingModal, setLoadingModal] = useState<boolean>(true);

  const [startTime, setStartTime] = useState<any>(null);

  const [endTime, setEndTime] = useState<any>(null);

  const [appointmentDate, setAppointmentDate] = useState<any>(null);

  const [specialityName, setSpecialityName] = useState<string>('');

  const [patientName, setPatientName] = useState<string>('');

  const [doctorName, setDoctorName] = useState<string>('');

  const [openModal, setOpenModal] = useState<boolean>(false);

  const storedUser = useSelector((state: RootState) => state.auth.user);

  const appointment = useSelector((state: RootState) => state.sidebar.motif);

  const { id: stationId } = useSelector((state: RootState) => state.auth.box);

  const { id: centerId } = useSelector((state: RootState) => state.auth.center);

  const [specialistStatus, setSpecialistStatus] = useState<userStatus>(userStatus.OFFLINE);

  const [buttonMessage, setButtonMessage] = useState<texts | null>(null);

  /* patient ongoing consultation */
  const { data: patientOngoingConsultations, isLoading: isLoadingPatientOngoingConsultation } = useGetOnGoingPatientConsultationQuery(appointment?.patientId);

  /* patient ongoing call */
  const { data: onGoingCalls, isLoading: isLoadingOngoingCall, isError: isErrorOngoingCall } = useGetConsultationOngoingCallQuery(patientOngoingConsultations?.results[0]?.id);

  /** get all online users, including the ones who are in a call */
  const onlineUsers: Array<any> = useSelector((state: RootState) => state.auth.onlineUsers);


  /* get doctor for checking availability, then we go socket */
  const { data, isLoading, isError } = useGetDoctorByIdQuery(appointment?.doctor?.id);


  const [showNotifySpecialistButton, setShowNotifySpecialistButton] = useState<boolean>(false);


  const [showNotifySpecialistAlternativeButton, setShowNotifySpecialistAlternativeButton] = useState<boolean>(false);


  const rightIsOpen = useSelector((state: RootState) => state.sidebar.rightIsOpen);







  /**
   * we will set here all appointment informations, and the specialist availability context
   */
  useEffect(() => {
    // set modal informations
    if (appointment) {
      setStartTime(dayjs(appointment.start).format("hh:mm A"));
      setEndTime(dayjs(appointment.end).format("hh:mm A"));
      setAppointmentDate(dayjs(appointment.start).format("DD MMM YYYY"));
      setSpecialityName(appointment?.doctor?.speciality?.name)
      setDoctorName(appointment?.doctor?.user?.firstName + ' ' + appointment?.doctor?.user?.lastName)
      setPatientName(appointment?.patient?.user?.firstName + ' ' + appointment?.patient?.user?.lastName)

    }


    // source priority:http connection -> socket connection -> ongoing consultation 

    // connection related context

    // http check
    if (!isLoading) { // cond 1
      if (data?.status === userStatus.OFFLINE) {  // cond 2
        console.log("check 2:specialist is offline");
        setSpecialistStatus(userStatus.OFFLINE); return;

      }
      if (data?.status === userStatus.IN_CALL) { // cond 3
        console.log("check 3:specialist is in call");

        setSpecialistStatus(userStatus.IN_CALL); return;
      }
    }


    // socket check
    if (appointment && onlineUsers.length) { // cond 4
      const doctors = onlineUsers.filter((u: any) => u.userId == appointment?.doctor?.userId)

      if (doctors?.length) { // cond 5

        setSpecialistStatus(doctors[0].status);
      } else { // cond 9
        console.log("check 9:specialist is in offline", doctors, appointment);

        setSpecialistStatus(userStatus.OFFLINE); return;
      }
    }
    else { // cond 10
      setSpecialistStatus(userStatus.ONLINE);
      console.log("check 10:specialist is in online");
    }

    if (!isLoadingPatientOngoingConsultation) {

      // ongoing consultations related context
      if (!patientOngoingConsultations || patientOngoingConsultations?.results?.length == 0) {
        // cond 11
        setButtonMessage(texts.SPECIALIST_NOT_NOTIFIED)
      }
    }


  }, [onlineUsers, appointment, isLoadingPatientOngoingConsultation, isLoading])



  /**
   * control notify specialist button
   */
  useEffect(() => {
    console.log("asserting",
      patientOngoingConsultations,
      !isLoadingPatientOngoingConsultation,
      patientOngoingConsultations, 
      !isLoadingOngoingCall, !isLoading
    )
    if (!isLoadingPatientOngoingConsultation && patientOngoingConsultations && !isLoadingOngoingCall  && !isLoading) {


      // the specialist is online
      const firstCondition = specialistStatus === userStatus.ONLINE;

      // there is an aleady oppened consultation
      const secondCondition = patientOngoingConsultations?.results && patientOngoingConsultations?.results?.length && patientOngoingConsultations?.results?.length > 0

      // there is no ongoing call
      const thirdCondition = !(onGoingCalls?.results?.length > 0 && specialistStatus === userStatus.IN_CALL);




      const condition = firstCondition && secondCondition && thirdCondition;


      console.log("conditions", firstCondition, secondCondition, thirdCondition)


      if (condition) setShowNotifySpecialistButton(true);
      else setShowNotifySpecialistButton(false);


      if (secondCondition && !condition) setShowNotifySpecialistAlternativeButton(true);
      else setShowNotifySpecialistAlternativeButton(false);


    }
  }, [
    specialistStatus, 
    isLoadingPatientOngoingConsultation,
    isLoadingOngoingCall,
    patientOngoingConsultations,
    onGoingCalls, 
    isLoading, 
    appointment])


  useEffect(()=> {
    console.log("whatching:", isLoadingOngoingCall)
  }, [isLoadingOngoingCall])



  /**
   * Specialist status whatcher, this will change button message
   */
  useEffect(() => {
    if (specialistStatus === userStatus.OFFLINE) setButtonMessage(texts.SPECIALIST_OFFLINE);
    else if (specialistStatus === userStatus.IN_CALL) setButtonMessage(texts.SPECIALIST_IN_CALL);
    else if (specialistStatus === userStatus.ONLINE) setButtonMessage(null);
  }, [specialistStatus]);



  /**
   * 
   */
  const handleOpen = () => {
    dispatch(setRightIsOpen(!rightIsOpen));
  };


  /**
   * 
   */
  const handleReturnToCall = () => {
    const callId = onGoingCalls.results[0].id;
    nav(`/doctor/call/${callId}`)
  }


  /**
   * notify the specialist doctor
   */
  const handleNotifySpecialist = async () => {
    console.log("we will notify app", appointment);
    const message = `le patient ${appointment?.patient?.user?.firstName} ${appointment?.patient?.user?.lastName} est prêt pour la télé-expertise, veuillez l'appeler.`;
     await notifySpecilist({
      to: [appointment?.doctor?.userId],
      content: message,
      type: `teleconsultationReminder`,
      customParam: { consultationId: patientOngoingConsultations?.results[0]?.id, appointmentId: appointment?.id, centerId, stationId }
    })
      .then((res) => {
        res.status === 201 ? changeStatusAppointment(appointment?.id, "honored") : ""
      }
      ).catch((err) => {
        toast.error("une erreur est survenue")
      })
    handleOpen()
    toast.success(`Le médecin à été bien notifié`);

  }


  /**
   * 
   */
  const cancelAppointement = async () => {
    const response = await cancelAppointment(appointment?.id, 'cancelled')
    if (response.status == 200) {
      setOpenModal(!openModal)
      toast.success('Rendez-vous annulé')
      window.location.reload()
    }

  }


  /**
   * 
   */
  const handleOpenModalConfirmation = () => {
    setOpenModal(!openModal)
    // handleOpen()
  };

  return (<>
    <Dialog
      open={rightIsOpen}
      handler={handleOpen}
      className="py-5 px-8 flex flex-col items-left "
      size="lg"
    >
      {!(isLoading && isLoadingOngoingCall) && <>
        <div className="time mb-4 text-[#5E5E5E] font-medium">
          <p className="text-[#5E5E5E] font-[600] text-xl ">Date / Horaire</p>
          <p className="text-[#1AA5C0] flex gap-4 ">
            <span>{appointmentDate || "-"}</span>
            <span className="flex gap-1">
              {startTime || "-"}-{endTime || "-"}
            </span>
          </p>
        </div>
        <div className="time mb-4">
          <div className="flex items-center gap-4">
            <p className="text-[#5E5E5E] text-xl font-[600]">Docteur spécialiste</p>

            {/* specialiste is connected */}
            {
              (!buttonMessage)
              &&
              (<div className="flex items-center gap-3">
                <div className="w-5 h-5 rounded-full bg-green-400">

                </div>
                <span className="text-green-400">Disponible</span>
              </div>
              )}

            {/* specialiste is in call */}
            {(onGoingCalls?.results?.length > 0
              &&
              onGoingCalls?.results[0].userId == appointment?.doctor?.userId
              &&
              specialistStatus === userStatus.IN_CALL

            ) && (<div className="flex items-center gap-3">
              <div className="w-5 h-5 rounded-full bg-yellow-500 ">

              </div>
              <span className="text-yellow-400 font-[600]">Vous êtes déja en appel avec ce médecin</span>
            </div>
              )}

            {/* specialiste is offline or already in call */}
            {([
              userStatus.OFFLINE,
              null,
              userStatus.IN_CALL].includes(specialistStatus)
              && (
                onGoingCalls?.results?.length == 0 || // no pending call
                onGoingCalls?.results[0].userId != appointment?.doctor?.userId // not the target center call
              )) && (<div className="flex items-center gap-3">
                <div className="w-5 h-5 rounded-full bg-red-500">

                </div>
                <span className="text-red-400 font-[600]">Non disponible </span>
              </div>
              )}

          </div>
          <p className="font-[400] text-[#1AA5C0]">{doctorName} - {specialityName || "-"}</p>
        </div>
        <div className="time mb-4">
          <p className="text-[#5E5E5E] text-xl font-[600]">Patient</p>
          <p className="font-[400] text-[#1AA5C0]">{patientName}</p>
        </div>




        {/* actions */}
        <div className="flex flex-column rounded-lg gap-3 mt-8 mx-auto">

          {/* open patient folder  */}
          <MyButton
            onClick={() => {
              handleOpen();
              nav(`/doctor/patient/${appointment.patientId}`);
            }}
            text="Accéder au dossier patient"
            rounded={false}
            color="text-white bg-secondary mb-4 w-[16rem]"
          />


          {/* notify the the doctor button */}
          {(showNotifySpecialistButton) &&
            <MyButton
              onClick={handleNotifySpecialist}
              text="Notifier le médecin"
              rounded={false}
              color="text-white bg-green-400 mb-4 w-[16rem]"
            />}

          {/* return to  the doctor button */}
          {
            (onGoingCalls?.results?.length > 0
              &&
              onGoingCalls?.results[0].userId == appointment?.doctor?.userId
              &&
              specialistStatus === userStatus.IN_CALL
            ) &&
            <MyButton
              onClick={handleReturnToCall}
              text="Revenir à l'appel"
              rounded={false}
              color="text-white bg-yellow-400 mb-4 w-[16rem]"
            />}



          {/* cancel appointment button */}
          <MyButton
            onClick={handleOpenModalConfirmation}
            text="Annuler rendez-vous"
            rounded={false}
            color="text-white bg-red-400 mb-4 w-[16rem]"
          />

          {(buttonMessage === texts.SPECIALIST_NOT_NOTIFIED) &&

            (
              <div className="flex items-center p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300" role="alert">
                <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                </svg>
                <span className="sr-only">Info</span>
                <div>

                  {buttonMessage}
                </div>
              </div>
            )
          }
          {(showNotifySpecialistAlternativeButton) &&
            (<span className="" onClick={handleNotifySpecialist}>Notifier quand même!</span>)}
        </div>
        {/* actions */}


      </>}{(isLoading && isLoadingOngoingCall) && <><Spinner className="mx-auto" /></>}

    </Dialog>


    <Dialog open={openModal} handler={handleOpenModalConfirmation}>
      <DialogHeader className="text-[#0D88B4]">Alert</DialogHeader>
      <DialogBody>
        <p className="text-black text-3xl text-center my-10">Es-tu sûr de vouloir annuler le rendez-vous</p>
      </DialogBody>
      <DialogFooter className="flex justify-between">
        <MyButton
          onClick={handleOpenModalConfirmation}
          text="Annuler"
          rounded={false}
          color="text-white bg-[#858585] mb-4 w-[16rem]"
        />

        <MyButton
          onClick={cancelAppointement}
          text="Confirmer"
          rounded={false}
          color="text-white bg-Bleu mb-4 w-[16rem]"
        />
      </DialogFooter>
    </Dialog>
  </>);
};




export default EventModal;
