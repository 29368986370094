import { useEffect, useMemo, useRef, useState } from "react";
import AgoraRTC from "agora-rtc-sdk-ng";
import { getConnectedDevices } from "../../helper/mediaDeviceHelper";
import VideoPlayer from "./VideoPlayer";
import devices from "../../helper/devicesInfos.json";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  selectMute,
  selectToggleScreen,
  setShowOthers,
} from "../../features/teleconsultation/CallActionsSlice";

import { updateAvailableMainCameras } from "../../features/Examen/defaultMainCameras";
import { getMainCameraId } from "../../features/teleconsultation/mainCameraIdSlice";
import hotToast from "react-hot-toast";
import { ReactComponent as WebRTCCamera } from "../../assets/webRTCCamera.svg";

const APP_ID = process.env.REACT_APP_APP_ID;
const client = AgoraRTC.createClient({
  mode: "rtc",
  codec: "vp8",
  //TODO: turn server
});

export const AgoraNurseCall = ({
  token,
  channel,
  users,
  setUsers,
  isMute,
  hangUp,
  isToggle,
  setCurrentUser,
  currentUser,
  mediaDevices,
}: any) => {
  const [localTracks, setLocalTracks] = useState<any>([]);
  const muteState = useSelector(selectMute);
  const toggleScreenState = useSelector(selectToggleScreen);
  const dispatch = useDispatch();
  const toast = useRef<any>(null);

  const show = (text: string) => {
    if (toast.current) {
      toast.current.show({ severity: "info", summary: "Info", detail: text });
    }
  };

  const handleUserJoined = async (user: any, mediaType: any) => {
    await client.subscribe(user, mediaType);

    if (mediaType === "video") {
      setUsers((previousUsers: any) => [...previousUsers, user]);
    }

    if (mediaType === "audio") {
      if (user.audioTrack) user.audioTrack.play();
    }
  };
  const handleUserLeft = (user: any) => {
    setUsers((previousUsers: any) =>
      previousUsers.filter((u: any) => u.uid !== user.uid)
    );
  };

  const showOthers = async () => {
    try {
      const connectedDevices = await getConnectedDevices(
        "videoinput",
        "audioinput",
        "Merci de verifier que votre camera et brancher",
        "Merci de verifier que votre audio et brancher"
      );

      const arrayDeviceIds = connectedDevices.map((device) => ({
        category: device.label,
        deviceId: device.deviceId,
        kind: device.kind,
      }));

      const dispatchedDevices = arrayDeviceIds.filter((device) => {
        if (
          !Object.values(mediaDevices).includes(device.deviceId) &&
          device.deviceId !== ""
        ) {
          dispatch(
            updateAvailableMainCameras({
              category: device.category,
              deviceId: device.deviceId,
              kind: device.kind,
            })
          );
          return true;
        }
        return false;
      });

      console.log("Dispatched devices:", dispatchedDevices);
      return dispatchedDevices;
    } catch (error) {
      console.log(error);
      // if (error?.includes("not found")) 
        dispatch(setShowOthers());
      acceptCameraToast("Veuillez autoriser l'accès à la caméra");
    }
  };

  useEffect(() => {
    const handelingMainStream = async (mainCamera: string) => {
      client.on("user-published", handleUserJoined);
      client.on("user-left", handleUserLeft);

      const [tracks, uid] = await Promise.all([
        AgoraRTC.createMicrophoneAndCameraTracks({}, { cameraId: mainCamera }),
        client.join(APP_ID, channel, token, null),
      ]);

      const [audioTrack, videoTrack] = tracks;
      setCurrentUser(uid);
      setLocalTracks(tracks);
      setUsers((previousUsers: any) => [
        ...previousUsers,
        { kind: "nurseCall", uid, videoTrack, audioTrack },
      ]);
      client.publish(tracks);
    };

    if (token) {
      showOthers().then((dispatchedDevices) => {
        if (dispatchedDevices) {
          const mediaDeviceLength = dispatchedDevices.filter(
            (entry) => entry.kind === "videoinput"
          );

          if (mediaDeviceLength.length > 1 || mediaDeviceLength.length == 0) {
            dispatch(setShowOthers());
          } else {
            handelingMainStream(mediaDeviceLength[0].deviceId);
          }
        }
      });
    }

    return () => {
      if (client.connectionState === "CONNECTED") {
        client.off("user-published", handleUserJoined);
        client.off("user-left", handleUserLeft);
        client.unpublish().then(() => client.leave());
      }
    };
  }, [mediaDevices]);

  const selectedDevice = useSelector(getMainCameraId);

  const tracks = useMemo(async () => {
    try {
      if (!selectedDevice.deviceId) {
        return;
      }

      client.on("user-published", handleUserJoined);
      client.on("user-left", handleUserLeft);

      const uid = await client.join(APP_ID, channel, token, null);

      if (selectedDevice.kind.includes("video")) {
        const [audioTrack, videoTrack] =
          await AgoraRTC.createMicrophoneAndCameraTracks(
            {},
            { cameraId: selectedDevice.deviceId! }
          );

        setCurrentUser(uid);
        setLocalTracks([audioTrack, videoTrack]);
        setUsers((previousUsers: any) => [
          ...previousUsers,
          { kind: "nurseCall", uid, videoTrack, audioTrack },
        ]);
        show("vous êtes connecter on utilisant seulement le camera");
        client.publish([audioTrack, videoTrack]);
      } else {
        const audioTrack = await AgoraRTC.createMicrophoneAudioTrack({
          microphoneId: selectedDevice.deviceId!,
        });

        setCurrentUser(uid);
        setLocalTracks((previousTracks: any) => [
          ...previousTracks,
          audioTrack,
        ]);
        setUsers((previousUsers: any) => [
          ...previousUsers,
          { kind: "nurseCall", uid, audioTrack },
        ]);
        show("vous êtes connecter on utilisant seulement le micro");
        client.publish([audioTrack]);
      }
    } catch (error) {
      console.error("Error joining Agora channel:", error);
    }
  }, [selectedDevice]);

  useEffect(() => {
    tracks.then(() => {
      return () => {
        if (client.connectionState === "CONNECTED") {
          client.off("user-published", handleUserJoined);
          client.off("user-left", handleUserLeft);
          client.unpublish().then(() => client.leave());
        }
      };
    });
  }, [tracks]);

  useEffect(() => {
    if (muteState) {
      console.log(`*NURSE* mute`, muteState);
      users.forEach((user: any) => {
        if (user.uid === currentUser) {
          user.audioTrack.setEnabled(false);
        }
      });
    } else {
      users.forEach((user: any) => {
        if (user.uid === currentUser) {
          user.audioTrack.setEnabled(true);
        }
      });
    }
  }, [muteState]);

  useEffect(() => {
    console.log("*NURSE* toggle the screnn", toggleScreenState);
    users.forEach((user: any) => {
      if (user.uid === currentUser) {
        user.videoTrack.setEnabled(!toggleScreenState);
      }
    });
  }, [toggleScreenState]);

  /**
   * camera Error message in case is not activated
   * @param text the show text message
   */
  const acceptCameraToast = (text: string) => {
    hotToast(
      (t) => (
        <span className="flex">
          {text}
          <button
            onClick={() => {
              hotToast.dismiss(t.id);
            }}
          >
            Ok
          </button>
        </span>
      ),
      {
        icon: <WebRTCCamera />,
      }
    );
  };

  return (
    <>
      {" "}
      {users.map((user: any) => {
        if (user.uid === currentUser) {
          return <VideoPlayer key={user.uid} user={user} />;
        }
        return null; // Or any fallback content if no user matches the condition
      })}
    </>
  );
};
