import { useDispatch } from "react-redux";
import devicesInfo from "../helper/devicesInfos.json";
import {
  getBetweenParenthesis,
  getConnectedDevices,
} from "../helper/mediaDeviceHelper";

import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { updateAvailableVideoDevices } from "../features/Examen/mediaDevicesSlice";
import { ExeName } from "@enums/vitalSigns";

export const useAvailableCameras = () => {
  const dispatch = useDispatch();
  let currentlabels: any[] = [];


  /* we will store the media devices that we'll get from the json file */
  const [availableVideaoDevices, setAvailableVideaoDevices] = useState<any>({});


  const getkeyName = (obj: any): string => {
    return Object.keys(obj)[0];
  };

  /**
   *
   * @param label
   * @param deviceId
   * @returns
   */
  const labelExists = (label: string, deviceId: number) => {
    return devicesInfo.some((category: any) =>
      Object.values(category).some((camerasArray: any) => {
        camerasArray.some((camera: any) => {
          if (camera.label.includes(label)) {
            // dispatch(
            // setAvailableVideaoDevices((prev: any) => ({
            //   ...prev,
            //   [getkeyName(category)]: deviceId,
            // }));
            // );

            dispatch(
              updateAvailableVideoDevices({
                category: getkeyName(category),
                deviceId,
              })
            );

            console.log(
              "[*]category",
              getkeyName(category),
              "[*]deviceId",
              deviceId
            );
            return true;
          } else {
            if (
              availableVideaoDevices &&
              currentlabels.length > 0
              // Object.values(availableVideaoDevices).filter(
              //   (value) => value !== null
              // ).length
            ) {
              if (currentlabels.includes(label!)) {
                // dispatch(
                // setAvailableVideaoDevices((prev: any) => ({
                //   ...prev,
                //   [getkeyName(category)]: null,
                // }));
                // );
              }
            }
            // console.log("currentlabels **", currentlabels, label);

            // console.log(
            //   "[*] not category",
            //   getkeyName(category),
            //   "[*] not deviceId",
            //   deviceId
            // )
            // return false;
          }
        });
      })
    );
  };

  /**
   *
   */
  const handelDeviceChange = async () => {
    try {
      const connectedDevices = await getConnectedDevices("videoinput","audioinput",   "merci de verifier le presence des dispositifs medicale",
      "merci de verifier la presence du Stethoscope"
     );
      connectedDevices.forEach((device) => {
        const devicelabel = getBetweenParenthesis(device.label);
        // if (!currentlabels.includes(devicelabel!))
        //   currentlabels.push(devicelabel!);
        // else currentlabels.filter((label) => label !== label);

        console.log("device label ", devicelabel);
        if (labelExists(devicelabel!, device.deviceId)) {
          console.log("device label exists");
        }
      });
    } catch (e) {
      console.log(e);
      toast.warn("Veuillez autoriser l'accès à la caméra");
    }
  };

  useEffect(() => {
    handelDeviceChange();
    if (navigator.mediaDevices && navigator.mediaDevices.addEventListener) {
      navigator.mediaDevices.addEventListener(
        "devicechange",
        handelDeviceChange
      );
    } else {
      console.error("MediaDevices API is not supported in this browser.");
      toast.warning("veillez utiliser un navigateur compatible comme : Chrome");
    }

    return () => {};
  }, [availableVideaoDevices]);

  return { availableVideaoDevices };
};
