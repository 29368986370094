import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import APP_CONSTANTS from "../../constants";
import { SLICE } from "../../enums/store";


export interface NotifiedObject {
    consultationId: number | null,
    stationId: number | null,
    generalistId:  number | null,
    centerId: number | null,
    appointmentId: number | null
}

interface NotfiedConsultation {
    notifiedConsultations?:Array<NotifiedObject> | null;
    currentNotifiedConsultation?: NotifiedObject | null
}



const initialState: NotfiedConsultation = {
    notifiedConsultations: JSON.parse(localStorage.getItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}notifiedConsultations`) || '[]'),
    currentNotifiedConsultation: null

};



const notifiedConsultationSlice = createSlice({
    name: SLICE.NOTIFIED_CONSULTATION,
    initialState,
    reducers: {
        updateNotifiedConsultations: (
            state,
            action: PayloadAction<any>,
        ) => {
    
                state.notifiedConsultations = action.payload;
                localStorage.setItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}notifiedConsultations`, JSON.stringify(Array.from(action.payload)));
        },
        updateCurrentNotifiedConsultation: (
            state,
            action: PayloadAction<any>,
        ) => {
    
                state.currentNotifiedConsultation = action.payload;
        },
      
    },
});


export const {
    updateNotifiedConsultations,
    updateCurrentNotifiedConsultation
  } = notifiedConsultationSlice.actions;


  
  export default notifiedConsultationSlice.reducer;
  
