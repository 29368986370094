import { useEffect, useState } from "react";
import AgoraRTC from "agora-rtc-sdk-ng";
import VideoPlayer from "./VideoPlayer";
import { AgoraVideoCallPropType } from "../../types";
import { getDevicesWithLabel } from "../../helper/mediaDeviceHelper";
import {
  selectHangUp,
  selectMute,
  selectToggleScreen,
} from "../../features/teleconsultation/CallActionsSlice";
import { useSelector } from "react-redux";

const APP_ID = process.env.REACT_APP_APP_ID;

const client = AgoraRTC.createClient({
  mode: "rtc",
  codec: "vp8",
});



/**
 * 
 * @param props 
 * @returns 
 */
export const AgoraDoctorCall = (props: AgoraVideoCallPropType) => {
  const {
    token,
    channel,
    users,
    setUsers,
    setCurrentUserStream,
    setCurrentUser,
    currentUser,
  } = props;


  
  const [localTracks, setLocalTracks] = useState<any>([]);
  const muteState = useSelector(selectMute);
  const hangingUp = useSelector(selectHangUp);
  const toggleScreenState = useSelector(selectToggleScreen);


  /**
   * 
   * @param user 
   * @param mediaType 
   */
  const handleUserJoined = async (user: any, mediaType: any) => {
    await client.subscribe(user, mediaType);

    if (mediaType === "video") {
      setUsers((previousUsers: any) => [...previousUsers, user]);
    }

    if (mediaType === "audio") {
      user.audioTrack.play();
    }
  };


  /**
   * 
   * @param user 
   */
  const handleUserLeft = (user: any) => {
    setUsers((previousUsers: any) =>
      previousUsers.filter((u: any) => u.uid !== user.uid)
    );
  };

  // useEffect(() => {
  //   client.on("user-published", handleUserJoined);
  //   client.on("user-left", handleUserLeft);

  //   client
  //     .join(APP_ID, "mediotTechnology", token, null)
  //     .then((uid) =>
  //       Promise.all([AgoraRTC.createMicrophoneAndCameraTracks(), uid])
  //     )
  //     .then(([tracks, uid]) => {
  //       const [audioTrack, videoTrack] = tracks;
  //       setLocalTracks(tracks);
  //       setUsers((previousUsers: any) => [
  //         ...previousUsers,
  //         {
  //           kind: "doctorCall",
  //           uid,
  //           videoTrack,
  //           audioTrack,
  //         },
  //       ]);
  //       client.publish(tracks);
  //     });

  //   return () => {
  //     client.off("user-published", handleUserJoined);
  //     client.off("user-left", handleUserLeft);
  //     client.unpublish().then(() => client.leave());
  //   };
  // }, []);

  useEffect(() => {
    const handellingMainCamera = async () => {

      try {
      navigator.mediaDevices.getUserMedia({ video: true, audio: true });


      client.on("user-published", handleUserJoined);
      client.on("user-left", handleUserLeft);

      const uid = await client.join(APP_ID, channel, token, null);


        const [tracks] = await Promise.all([
          AgoraRTC.createMicrophoneAndCameraTracks(
            { encoderConfig: "standard_stereo" }
            // { cameraId: ptzCamera[0].deviceId }
          ),
          uid,
        ])
        const [audioTrack, videoTrack] = tracks;
        setLocalTracks(tracks);
        setCurrentUser(uid);
        setUsers((previousUsers: any) => [
          ...previousUsers,
          { client: client, kind: "doctorCall", uid, videoTrack, audioTrack },
        ]);
        client.publish(tracks);

      } catch (error) {
        if (error.code == 'DEVICE_NOT_FOUND') {
          // informe the user and the interlocutor that the device is not accessible
          // alert('veuillez verifier votre audio et votre camera')
          navigator.mediaDevices.getUserMedia({ video: false, audio: true });

          // await AgoraRTC.createMicrophoneAudioTrack()
        }
        console.log(error)
      }

      //   .then(([tracks, uid]) => {

      //   })
      //   .catch((err)=> {
      //     console.log("error detected", err)
      //   })
    };
    if (token) {
      console.log("token generated", token);
      handellingMainCamera();
    }

    return () => {
      if (token) {
        for (let trackName in users) {
          console.log("leave Track", trackName);
          var track = localTracks[trackName];
          if (track) {
            track.stop();
            track.close();
            localTracks[trackName] = undefined;
          }
        }
        client.off("user-published", handleUserJoined);
        client.off("user-left", handleUserLeft);
        client.unpublish().then(() => client.leave());
      }
    };
  }, [token]);

  useEffect(() => {
    if (muteState) {
      console.log(`mute`, muteState);
      users.filter((user: any) => {
        if (user.uid === currentUser) {
          user.audioTrack.setEnabled(false);
        }
      });
    } else {
      users.filter((user: any) => {
        if (user.uid === currentUser) {
          user.audioTrack.setEnabled(true);
        }
      });
    }
  }, [muteState]);

  useEffect(() => {
    const turnOnVideo = async () => {
      users.filter((user: any) => {
        if (user.uid === currentUser) {
          console.log("turn on the video", user);
          user.videoTrack.setEnabled(true);
          // user.videoTrack = user.client.createCameraVideoTrack({
          //   encoderConfig: "480p",
          //   optimizationMode: "motion",
          //   // scalabiltyMode: "1SL1TL",
          // });
          // user.client.publish(user.videoTrack);
          // user.videoTrack.setEnabled(true)
        }
      });
    };
    console.log("toggle the screnn", toggleScreenState);
    if (toggleScreenState)
      users.filter((user: any) => {
        if (user.uid === currentUser) {
          user.videoTrack.setEnabled(false);
          // user.videoTrack.stop();
          // user.videoTrack.close();
          // user.client.unpublish(user.videoTrack);
        }
      });
    else {
      turnOnVideo();
    }
  }, [toggleScreenState]);

  return (
    <>
      {" "}
      {users.map((user: any) => {
        if (user.uid === currentUser) {
          return <VideoPlayer key={user.uid} user={user} />;
        }
        // return null; // Or any fallback content if no user matches the condition
      })}
    </>
  );
};
